<template>
  <div class="client-list-wrap">
    <p class="users">{{ $t('Clients') }}</p>

    <div class="search-action-wrap">
      <div class="search" v-if="hasPermission('clients_view')">
        <div class="md-layout-item">
          <md-field>
            <img src="../../assets/images/search.svg" alt="search" class="search-select">
            <md-input v-model="name" :placeholder="$t('Name')" @input="filter"/>
          </md-field>
        </div>
      </div>

      <div class="actions">
        <button
            class="btn-create"
            @click="handlerDelete()"
            v-if="hasPermission('client_delete')"
            v-bind="{'disabled' : selected.length < 1}"
        >
          <img src="../../assets/images/delete.png" alt="arrow">
          <p>{{ $t('delete') }}</p>
        </button>

        <router-link to="/clients/create">
          <button class="btn-create" v-if="hasPermission('client_create')">
            <p>{{ $t('create') }}</p>
            <img src="../../assets/images/Path.png" alt="arrow">
          </button>
        </router-link>
      </div>
    </div>

    <md-table>
      <md-table-row md-selectable="multiple" class="head_row">
        <md-table-head>

          <div class="permissions-wrap">
            <md-checkbox
                v-model="selectAll"
                @change="select"
            >
            </md-checkbox>
          </div>
        </md-table-head>
        <md-table-head
            v-for="(item, i) in headData"
            :key="i"
        >
          <md-ripple class="md-table-title">
            <div @click="customSorting(item.name, initialSort, i)">

              {{ $t(item.title) }}
              <img class="md-size-2x arrow_down" :class="`arrow_down_${i}`" src="../../assets/images/arrow_down.svg"/>
            </div>
          </md-ripple>
        </md-table-head>
        <md-table-head class="actions">{{ $t('actions') }}</md-table-head>
      </md-table-row>
<!--      <pre>-->
<!--        {{clientsManager}}-->
<!--      </pre>-->
      <md-table-row v-for="item in clients" :key="item.id">
        <md-table-cell>
          <div class="permissions-wrap">
            <md-checkbox
                v-model="selected"
                :value="item.id"
            >
            </md-checkbox>
          </div>

        </md-table-cell>
        <md-table-cell>{{ item.name }}</md-table-cell>
        <md-table-cell>{{ $t(getStatus(item.status)) }}</md-table-cell>
        <md-table-cell>{{ item.description }}</md-table-cell>
        <md-table-cell>{{
            getReadableDate(item.created_at)
          }}
        </md-table-cell>
        <md-table-cell>{{
            getReadableDate(item.updated_at)
          }}
        </md-table-cell>
        <md-table-cell md-label="Actions" v-if="hasPermission('client_update') || hasPermission('client_delete')">
          <div :class="{action: 'permission'}">
            <router-link
                :to="{ name: 'clients-edit', params: { id: item.id }}"
            >
              <md-button class="md-dense md-raised" v-if="hasPermission('client_update')">
                <md-icon>edit</md-icon>
              </md-button>
            </router-link>
            <br>
            <md-button class="md-raised md-accent" @click="handlerDelete(item.id)"
                       v-if="hasPermission('client_delete')">
              <md-icon>delete</md-icon>
            </md-button>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>

    <custom-pagination
        :list="clients"
        :page="pagination.current_page"
        :pagesLength="pagesLength"
        :changePage="changePage"
    />
  </div>
</template>

<script>
import {userPermissions} from '@/mixins/userPermissions';
import {mapGetters, mapActions} from "vuex";
import CustomPagination from "../../components/pagination/CustomPagination";

export default {
  name: 'clients-list',
  components: {CustomPagination},
  data: () => ({
    listUrl: process.env.VUE_APP_API_URL + "/clients/list",
    selectAll: false,
    selected: [],
    clients: [],
    filteredClients: [],
    name: '',
    loggedManagerId: null,
    role: '',
    permission: false,
    pagination: {
      current_page: null,
      from: null,
      last_page: null,
      per_page: null,
      total: null
    },
    sortName: '',
    sort: '',
    initialSort: "desc",
    headData: [
      {title: 'Name', name: 'name', asc: false, desc: true},
      {title: 'Status', name: 'status', asc: false, desc: true},
      {title: 'Description', name: 'description', asc: false, desc: true},
      {title: 'Created at', name: 'created_at', asc: false, desc: true},
      {title: 'Updated at', name: 'updated_at', asc: false, desc: true},
    ],
  }),

  mixins: [
    userPermissions
  ],

  computed: {
    ...mapGetters(['clientsList', 'reqPagination']),
    pagesLength() {
      return Math.ceil(+this.pagination.total / +this.pagination.per_page)
    },
  },

  async mounted() {
    let users = JSON.parse(localStorage.getItem('user'))
    if (users.user.roles[0].name === "manager") {
      this.loggedManagerId = users.user.id
    }
    await this.getPaginatedList({})
  },

  methods: {

    async select() {
      this.selected = [];
      if (this.selectAll) {
        for (let i in this.clients) {
          this.selected.push(this.clients[i].id);
        }
      }
    },
    async customSorting(sortName, sort, i) {
      if (this.initialSort === "desc") {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(180deg)'
        this.initialSort = "asc"
      } else {
        document.querySelector(`.arrow_down_${i}`).style.transform = 'rotate(0deg)'
        this.initialSort = "desc"
      }
      this.headData.map(item => {
        if (sort === 'desc' && item.name === sortName) {
          item.desc = false
          item.asc = true
        } else {
          item.asc = false
          item.desc = true
        }
      })
      this.sortName = sortName
      this.sort = sort
      await this.getPaginatedList({
        page: this.pagination.current_page,
        name: this.name,
        sort: sortName,
        sortBy: sort,
        managerId: this.loggedManagerId,
      })
    },

    getPaginatedList({page = 1, name = '', sort = '', sortBy = 'desc', managerId = this.loggedManagerId}) {
      this.getClientsList({page, name, sort, sortBy, managerId})
          .then(data => {
              this.clients = data
            this.pagination.current_page = this.reqPagination.current_page
            this.pagination.from = this.reqPagination.from
            this.pagination.last_page = this.reqPagination.last_page
            this.pagination.per_page = this.reqPagination.per_page
            this.pagination.total = this.reqPagination.total
          })
    },
    ...mapActions(['getClientsList']),
    changePage(currentPage) {
      this.getPaginatedList({page: currentPage, name: this.name, sort: this.sortName, sortBy: this.sort, managerId: this.loggedManagerId,})
    },

    filter() {
      this.getPaginatedList({page: 1, name: this.name, sort: this.sortName, sortBy: this.sort, managerId: this.loggedManagerId,})
    },

    onSelect(items) {
      let itemIds = [];
      items.map(item => {
        itemIds.push(item.id);
      });
      this.selected = itemIds.filter((item, pos) => {
        return itemIds.indexOf(item) === pos;
      });
    },

    getAlternateLabel(count) {
      let plural = '';

      if (count > 1) {
        plural = 's'
      }

      return `${count} client${plural} selected`
    },

    axiosHandler(id) {
      this.$axios
          .delete(process.env.VUE_APP_API_URL + "/clients/" + id + "/delete")
          .then(() => {
            this.clients = this.clients.filter(el => el.id !== id)
            this.setClientsList(this.clients);
          })
          .catch(error => console.log(error))
    },
    async handlerDelete(id = null) {
      let conf = confirm("Are you sure?");

      if (!conf) {
        return;
      }

      if (id != null) {
        this.axiosHandler(id)
      } else {
        let ids = this.selected;
        let actions = [];

        ids.map(id => {
          actions.push(
              this.axiosHandler(id)
          )
        });
        try {
          await Promise.all(actions)
        } catch (e) {
          console.log(e)
        }
      }

    },

    hasPermission(name) {
      this.permission = true;
      return this.userPermissions.indexOf(name) >= 0
    },

    getStatus(item) {
      return item ? 'Active' : 'Inactive'
    },
    getReadableDate(date) {
      let d = new Date(date);

      return d.toDateString();
    }
  }
}
</script>

<style lang="scss">

.md-checkbox.md-theme-default.md-checked .md-checkbox-container {
  background-color: #054F63 !important;
}

.client-list-wrap {
  padding: 0 35px 0 21px;

  .search-action-wrap {
    display: flex;
    justify-content: space-between;
  }

  .md-input {
    padding-left: 50px !important;
    position: absolute;
    top: 8px;
  }

  .md-table-head-label {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
  }

  .md-table-title > div {
    color: #000000 !important;
    font-size: 16px;
    font-weight: bold;
  }

  .arrow_down:hover {
    cursor: pointer;
  }

  .md-table-title:hover {
    cursor: pointer;
  }

  .arrow_down {
    height: 18px !important;
    overflow: auto;
    transition: 0.25s ease;
  }

  .head_row {
    border-bottom: 1.5px solid #000000 !important;
  }

  .md-checkbox {
    margin: 8px 0 0 20px;
    display: inline-flex;
    position: relative;
    height: 18px;
    width: 18px;
    border: unset !important;
  }

  .users {
    margin: 0 0 20px;
    font-weight: 900;
    font-size: 36px;
    line-height: 52px;
    color: #000000;
  }

  .md-table-sortable-icon {
    opacity: 1 !important;

    svg {
      fill: #EC8B31 !important;
      height: 18px !important;
    }
  }

  .btn-create:hover {
    background: #F7F7F8;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .md-button {
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 6px;
    width: 36px !important;
    height: 36px;
    min-width: unset;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-primary .md-icon-font, .md-button.md-theme-default.md-raised:not([disabled]).md-accent .md-icon-font {
    color: #7f7878 !important;
    background-color: white;
  }

  .md-button.md-theme-default.md-raised:not([disabled]).md-accent {
    background-color: white;
  }


  .btn-create {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 146px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;

    p {
      margin: 0;
      padding: 0 15px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
  }

  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    width: 158px;
    border: 1px solid #CDD0D9;
    border-radius: 6px;

    p {
      margin: 0;
      padding: 12px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }

    img {
      margin-left: 15px;
    }

  }

  .md-table.md-theme-default .md-table-content, .md-table.md-theme-default .md-table-alternate-header {
    height: unset !important;
    max-height: unset !important;
  }

  .md-table {
    margin-top: 30px;
    box-shadow: unset !important;
  }

  .md-table + .md-table {
    margin-top: 16px
  }

  .search {
    display: flex;

  }

  .md-layout-item {
    flex: unset;
    height: 48px;
  }

  .md-field {
    width: 289px;
    background: #FFFFFF;
    border: 1px solid #CDD0D9;
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 0;

    &::after {
      background-color: unset !important;
    }
  }

  .md-input {
    height: unset !important;
  }

  .md-select {
    .md-icon-image {
      position: absolute;
      right: 0;
      left: 75%;
      top: 13px;
    }
  }

  .search-select {
    position: absolute;
    left: 15px;
  }

  .md-ripple, .md-table-cell-container {
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
    text-align: left;
  }

  .md-table-fixed-header-container > table > thead > tr {
    border-bottom: 1px solid #000000;;
  }

  .md-checkbox .md-checkbox-container {
    width: 18px !important;
    min-width: 18px !important;
    height: 18px !important;
    border: 1px solid #CDD0D9 !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;
  }

  .md-table-cell-selection .md-checkbox {
    margin: 0 47px 0 37px;
  }

  .md-table-cell-selection .md-checkbox .md-checkbox-container:after {
    top: 0 !important;
    left: 5px !important;
  }

  .md-table-cell {
    .action {
      display: flex;
    }
  }


  .md-table-head-container {
    padding-top: 10px !important;
    padding: unset;
  }

  .md-table-head:last-child {
    .md-table-head-label {
      padding-right: 24px !important;
    }
  }

  .md-table-head:last-child {
    .md-table-head-container {
      text-align: right;
      padding-right: 22px;
    }
  }

  .md-table-cell:last-child {
    .md-table-cell-container {
      display: flex;
      justify-content: flex-end;
    }
  }

}
</style>
<style lang="scss">
.client-list-wrap {
  .md-table-head-label {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    padding-right: unset !important;
    padding-left: 22px !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>